<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0" @scroll.passive="handleScroll">
          <b-table
            @row-contextmenu="handleContextMenuEvent"
            id="myTables"
            striped
            small
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="displayedData"
            :fields="fields"
            v-hotkey="{ F2: getAudio }"
            head-row-variant="secondary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(billsec)="data">
              {{
                parseInt(data.item.billsec / 60) +
                ":" +
                (data.item.billsec % 60 < 10
                  ? "0" + (data.item.billsec % 60)
                  : data.item.billsec % 60)
              }}
            </template>
            <template #cell(performer_id)="data">
              <div
                @dblclick="getDrivers(data)"
                v-if="data.item.performer_id"
                class="reference-field"
              >
                {{ data.item.performer_id }}
              </div>
            </template>
            <template #cell(order_id)="data">
              <div @dblclick="getOrderHistory(data)" class="reference-field">
                {{ data.item.order_id }}
              </div>
            </template>
            <template #cell(path_record)="data">
              <div>
                {{ data.item.path_record ? "Да" : "Нет" }}
              </div>
            </template>
            <template #cell(disposition)="data">
              <div v-if="data.item.disposition == 'ANSWERED'">Принят</div>
              <div v-if="data.item.disposition == 'NO ANSWER'">Не принят</div>
              <div v-if="data.item.disposition == 'BUSY'">Не дозвонился</div>
              <div v-if="data.item.disposition == 'FAILED'">Ошибка</div>
              <div v-if="data.item.disposition == 'CONGESTION'">
                Соеденение прервано
              </div>
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link @click="call()" class="d-flex align-items-center">
                <feather-icon icon="PhoneOutgoingIcon" size="16" />
                <span class="ml-75">Позвонить</span>
              </b-link>
              <b-link @click="getAudio()" class="d-flex align-items-center">
                <feather-icon icon="PlayIcon" size="16" />
                <span class="ml-75">Прослушать</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueContext from "vue-context";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import lazyLoading from "@/@core/mixins/utils/lazyLoading";
export default {
  mixins: [lazyLoading],
  components: {
    VueContext,
    ToastificationContent,
  },
  props: ["dataTable"],
  data() {
    return {
      openCar: false,
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "ID", sortable: true, thStyle: { width: "40px" } },
        {
          key: "uniqueid",
          label: "UID",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "path_record",
          label: "Запись звонка",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "calldate",
          label: "Дата звонка",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "disposition",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "src",
          label: "Кто звонил",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "dst",
          label: "Куда звонил",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "billsec",
          label: "Длительность",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_id",
          label: "Исполнитель",
          sortable: true,
          thStyle: { width: "88px" },
        },
        {
          key: "order_id",
          label: "ID заказа",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "driver_profile_id",
          label: "ID анкетты",
          sortable: true,
          thStyle: { width: "75px" },
        },
        {
          key: "memo_id",
          label: "ID СЗ",
          sortable: true,
          thStyle: { width: "45px" },
        },
        {
          key: "lastdata",
          label: "Последние данные",
          sortable: true,
          thStyle: { width: "150px" },
        },
        // {key: 'linkedid', label: 'Запись', sortable: true, thStyle: { width: '500px' }},
      ],
      selectedPhone: null,
      audioSrc: null,
    };
  },
  methods: {
    call() {
      if (this.$store.state.caller.status) {
        let initiatorNumber = JSON.parse(
          localStorage.getItem("sipConfig")
        ).user;
        this.$http
          .post("/connect-driver-operator", {
            number: initiatorNumber,
            phone: "992" + this.selectedPhone,
          })
          .then(() => {
            this.$store.commit("caller/UPDATE_IS_INITIATOR_STATE", true);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Запрос на соединение отправлен",
                icon: "XIcon",
                variant: "success",
                text: "",
              },
            });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Ошибка",
                icon: "XIcon",
                variant: "danger",
                text: err,
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Включите SIP телефонию",
            icon: "XIcon",
            variant: "danger",
            text: "",
          },
        });
      }
    },
    getAudio() {
      this.$emit("getSrc", this.audioSrc);
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.selectedPhone = item.src;
      this.audioSrc = item.path_record;
    },
    getDrivers(data) {
      this.$router.push({
        name: "infoDriver",
        query: { filter: data.item.performer_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.performer_id,
        `/infoDriver`,
        data.item.performer_id,
      ]);
    },
    getOrderHistory(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "История " + data.item.order_id,
        `/order/order-history`,
        data.item.order_id,
      ]);
      this.$router.push({
        name: "orderHistory",
        query: { filter: data.item.order_id },
      });
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.textContent.trim();
      this.audioSrc = item.path_record;
      cellSelect();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/table.scss";
</style>
